<template>
  <div class="default-tabela cupom-tabela">
    <div class="tabela-titulo">
      <h1>{{ camposInput.titulo }}</h1>
    <div>
      <DownloadButton
        area="relatorio_de_participacoes"
        :getData="downloadExcelAll"
        :title="'Relatório de Cupons Moderados'"
      />
      
      <!-- <DownloadButton
        area="relatorio_de_cupons_Total"
        :getData="getDataTotal"
        :list="planilhaParaDownload"
        :title="'Relatório de Cupom'"
      /> -->
  </div>
    </div>
    <div class="tabela-subtitulo default-formulario">
      <div class="formulario-item">
        <label class="formulario-item-label">Nome:</label>
        <input type="text" v-model="form.nome" placeholder="Nome" />
      </div>

      <div class="formulario-item">
        <label class="formulario-item-label">CPF:</label>
        <input
          type="text"
          v-model="form.cpf"
          v-mask="'999.999.999-99'"
          placeholder="000.000.000-00"
        />
      </div>
      <!-- <div class="formulario-item">
        <label class="formulario-item-label">E-mail:</label>
        <input
          type="text"
          v-model="form.email"
          placeholder="email@exemplo.com"
        />
      </div> -->
      <!-- <div class="formulario-item">
        <label class="formulario-item-label">Número do cupom:</label>
        <input
          type="text"
          v-model="form.numeroCupom"
          placeholder="0000000000"
        />
      </div> -->
      
      <div class="formulario-item" >
        <label class="formulario-item-label">Filtre por status</label>
        <multiselect
          v-model="form.status"
          :options="statusCupom"
          label="descricao"
          track-by="id"
          :searchable="false"
          :allow-empty="false"
          placeholder="Selecione"
          select-label="Filtrar"
          deselect-label="Selecionado"
          selected-label="Selecionado"
          @close="fecharSelect('status')"
        />
      </div>
      <!-- <div v-if="!this.semStatus" class="formulario-item cupom-checkbox">
        <input
          type="checkbox"
          id="input-premiacao-instantanea"
          v-model="form.premiacaoInstantanea"
        />
        <div>
          <label class="label-check-fake" for="input-premiacao-instantanea">
            <span></span>
            <span></span>
          </label>
        </div>
        <label class="label-nova-comunicacao formulario-item-label"
          >Filtrar cupom por premiação instantânea</label
        >
      </div> -->
    </div>

    <div class="tabela-subtitulo default-formulario">

      <div class="btns-formulario" style="margin-left: auto;">
        <button class="btn btn-enviar" @click="filtrar">Filtrar</button>
        <button class="btn" @click="limparFiltros">Limpar</button>
      </div>

      <!-- .tabela-filtro -->
    </div>

    <transition name="fade-left" mode="out-in">
      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados no momento</p>
      </div>
      <div v-else class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-cupom-numero"><p>Nome</p></div>
          <div class="tabela-linha-cupom-cpf"><p>CPF</p></div>
          <div class="tabela-linha-cupom-email"><p>E-mail</p></div>
          <div class="tabela-linha-cupom-cadastro">
            <p>Data Moderação</p>
          </div>
          <div class="tabela-linha-cupom-status"><p>Status</p></div>
          <div class="tabela-linha-cupom-premio"><p>Prêmio</p></div>
          <!-- <div class="tabela-linha-cupom-meio"><p>Meio de cadastro</p></div> -->
          <div class="tabela-linha-btns"></div>
        </div>
        <div
          class="tabela-linha tabela-linha-informacoes"
          v-for="(itemTabela, index) in dadosTabela"
          :key="index"
        >
          <div class="tabela-linha-cupom-numero">
            <p>{{ itemTabela.nome }}</p>
          </div>
          <div class="tabela-linha-cupom-cpf">
            <p v-if="itemTabela.cpf">{{ itemTabela.cpf | formataCPF }}</p>
            <p v-if="!itemTabela.cpf">Não informado</p>
          </div>
          <div class="tabela-linha-cupom-email">
            <p v-if="itemTabela.email">{{ itemTabela.email }}</p>
            <p v-if="!itemTabela.email">Não informado</p>
          </div>
          <div class="tabela-linha-cupom-cadastro">
            <p>{{ itemTabela.dataCadastro | filterData }}</p>
          </div>
          <div class="tabela-linha-cupom-status">
            <p
              v-if="itemTabela.status"
              :class="`cupom-status ${itemTabela.status}`"
            >
              {{ itemTabela.status }}
            </p>
            <p v-if="!itemTabela.status">Não informado</p>
          </div>
          <div class="tabela-linha-cupom-premio">
            <p v-if="itemTabela.valeBrinde">{{ itemTabela.valeBrinde }}</p>
            <p v-if="!itemTabela.valeBrinde">Não premiado</p>
          </div>
          <!-- <div class="tabela-linha-cupom-meio">
            <p>{{ itemTabela.meioDeCadastro }}</p>
          </div> -->
          <div class="tabela-linha-btns">
            <div class="tabela-linha-btns-editar" @click="moderarItems(itemTabela.participacaoId)">
              <img src="~@/assets/images/icones/see.svg" alt="Detalhes" title="Detalhes" />
            </div>
          </div>
          
        </div>
      </div>
    </transition>
    <!-- .tabela-paginacao -->
    <div class="tabela-paginacao paginacao" v-if="!semDados">
      <div>
        <p>Total</p>
      </div>
      <div>
        {{ paginacao.totalPaginas }}
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina >= 2"
        @click="nudaPagina(1)"
      >
        <p>1</p>
      </div>
      <div>
        <div class="button" v-if="pageNumber > 1" @click="prevPage()">
          <img
            src="~@/assets/images/icones/next.svg"
            alt="<"
            title="Anterior"
            style=" transform: rotate(180deg);"
          />
        </div>
      </div>
      <div
        v-if="paginacao.numeroPagina > 1"
        @click="nudaPagina(paginacao.numeroPagina - 1)"
      >
        <p>{{ paginacao.numeroPagina - 1 }}</p>
      </div>
      <div class="pagina-atual" v-if="paginacao.numeroPagina >= 1">
        {{ paginacao.numeroPagina }}
      </div>
      <div
        v-if="
          paginacao.totalPaginas >= 3 &&
            paginacao.numeroPagina < paginacao.totalPaginas
        "
        @click="nudaPagina(paginacao.numeroPagina + 1)"
      >
        <p>{{ paginacao.numeroPagina + 1 }}</p>
      </div>
      <div>
        <div
          class="button"
          v-if="dadosTabela.length === 10"
          @click="nextPage()"
        >
          <img src="~@/assets/images/icones/next.svg" alt=">" title="Próximo" />
        </div>
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina < paginacao.totalPaginas"
        @click="nudaPagina(paginacao.totalPaginas)"
      >
        <p>{{ paginacao.totalPaginas }}</p>
      </div>
    </div>
    <!-- <Paginacao :paginacao="paginacao" pagina="cupom"  v-if="!semDados"/> -->
    <!-- .tabela-paginacao -->
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import {
  cupomListarStatus,
  gerarRelatorio,
  gerarRelatorioTotal,
  cupomListarTodos,
  GetExcelAdminById
} from "@/api/cupom-moderado";
import { _imagemMobile, _imagemDesktop } from "@/api/_caminhosApi";
import { numberToReal } from "@/utils/money";
import { filterData } from "@/utils/date";
import { formataCPF } from "@/utils/masks";
import DownloadButton from "@/components/download-button/DownloadButton.vue";
// import Paginacao from "@/components/paginacao/Paginacao.vue";
import Multiselect from "vue-multiselect";
import moment from "moment";
import { ptBR } from "vuejs-datepicker/dist/locale";

export default {
  directives: {
    mask: AwesomeMask
  },
  data() {
    return {
      ptBR: ptBR,
      pageNumber: 1,
      filtroBotao: false,
      semDados: true,
      semStatus: true,
      planilhaParaDownload: [],
      tabelaPaginada: [],
      minhasLabels: {
        first: "",
        last: "",
        previous: "",
        next: ""
      },
      form: {
        status: { descricao: "Todos", id: "" },
        premiacaoInstantanea: false,
        cpf: null,
        numeroCupom: null,
        dataInicial: null,
        dataFinal: null,
        nome: null,
        email: null
      }
    };
  },
  components: {
    Multiselect,
    DownloadButton,
    // Paginacao
  },
  computed: {
    camposInput() {
      return this.$store.state.cupom.componenteAtivado;
    },
    dadosTabela: {
      get() {
        return this.$store.state.cupom.tabela;
      },
      set(data) {
        return (this.tabelaPaginada = data);
      }
    },
    statusCupom() {
      return this.$store.state.cupom.statuses;
    },
    paginacao() {
      return this.$store.state.cupom.paginacao;
    }
  },
  methods: {
    _imagemMobile,
    _imagemDesktop,
    numberToReal,
    gerarRelatorio,
    gerarRelatorioTotal,
    montarDownload(data) {
      console.log(data)
      if (data && Object.keys(data).length) {
        return (this.planilhaParaDownload = data.map(v => ({
          "ID Cupom" : v.id,
          Nome: v.nome,
          CPF: this.formataCPF(v.cpf),
          "Data de cadastro": moment(v.dataCadastro).format("DD/MM/YYYY"),
          "Hora de cadastro": moment(v.dataCadastro).format("HH:mm"),
          "Produto Cadastrado": v.produtoCadastrado,
          "Quantida de Produto": v.quantidadeProduto,
          "Número Cupom": v.numeroCupom,
          "Status do Cupom": v.cupomStatus,
          "Moderação": v.cupomStatus == "Reprovado" ? v.logModeracao : "",
          "Meio de Cadastro": v.meioDeCadastro,
          CNPJ: this.formataCNPJ(v.cnpj),
          UF: v.uf,
          "Valor Total Produtos": this.formatarMoeda(Number(v.valorTotal)),
          "Data Compra": moment(v.dataCompra).format("DD/MM/YYYY"),
          "Premiação Instantanea": v.premiacaoInstantanea
        })));
      }
    },

    montarDownloadTotal(data) {
      console.log(data)
      if (data && Object.keys(data).length) {
        return (this.planilhaParaDownload = data.map(v => ({
          "ID Cupom" : v.id,
          Nome: v.nome,
          CPF: this.formataCPF(v.cpf),
          "Número Cupom": v.numeroCupom,
          "Status do Cupom": v.cupomStatus,
          "Motivo Cupom": v.cupomStatus == "Reprovado" ? v.logModeracao : "",
          CNPJ: this.formataCNPJ(v.cnpj),
          "Data Compra": moment(v.dataCompra).format("DD/MM/YYYY"),
          UF: v.uf,
          "Data de cadastro": moment(v.dataCadastro).format("DD/MM/YYYY"),
          "Valor Total Produtos": this.formatarMoeda(Number(v.valorTotal)),
          "Cadastrado WhatsApp": v.meioDeCadastro == "WEB" ? "Não" : "Sim",
        })));
      }
    },
    formatarMoeda(number) {
      if (number)
        return number.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL"
        });
    },
    replaceLast(number) {
      let str = number.toString();
      str = str.replace(/\./g, "");

      if (str.length == 3) {
        str = str.substring(0, str.length + 2) + "0";
      }
      if (str.length == 2) {
        str = str.substring(0, str.length + 2) + "00";
      }
      if (str.length > 3) {
        number =
          str.substring(0, str.length - 2) +
          "." +
          str.substring(str.length - 2);
      }
      return this.formatarMoeda(Number(number));
    },
    getDD(number) {
      if (number) return number.substring(0, 2);
    },
    removeDD(number) {
      if (number) return (number = number.replace(/^.{2}/g, ""));
    },
    changePage(items) {
      this.dadosTabela = items;
    },
    prevPage() {
      this.pageNumber = this.pageNumber - 1;
    },
    nextPage() {
      this.pageNumber = this.pageNumber + 1;
    },
    nudaPagina(id) {
      this.pageNumber = id;
    },
    buscaStatus() {
      return cupomListarStatus();
    },
    novoEnvio() {
      this.$store.commit("cupom/SET_TIPO_FORMULARIO", "Novo");
      this.$store.commit("cupom/SET_CUPOM_TELA", "Formulario");
    },

    verificaComponente() {
      cupomListarTodos(this.pageNumber, 10, this.filtros, this.filtroBotao);
    },

    filtrar() {
      this.filtros = this.form;
      this.pageNumber = 1
      this.filtroBotao = true
      this.verificaComponente();
      this.filtroBotao = false
    },

    limparFiltros() {
      this.form = {
        status: { descricao: "Todos", id: "" },
        premiacaoInstantanea: false,
        cpf: null
      };
      this.filtros = null;
      this.filtroBotao = false
      this.verificaComponente();
    },
    editarItem(id) {
      this.$store.commit("cupom/SET_TIPO_FORMULARIO", "Editar");
      this.$store.commit("cupom/SET_CUPOM_TELA", "Formulario");
      this.$store.commit("cupom/SET_ID_FORMULARIO_EDITAR", id);
    },
    
    formataCPF(cpf) {
      cpf = cpf.replace(/[^\d]/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    formataCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]/g, "");
      return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
    removerPontuacao(cpf) {
      cpf = cpf.replace(/[^\d]/g, "");
      return cpf;
    },
    fecharSelect(item) {
      if (!this.form[item] || this.form[item].length < 1) {
        this.form[item] = null;
      }
      this.pageNumber = 1;
    },
    // getData() {
    //   return this.gerarRelatorio().then(resp => {
    //     return this.montarDownload(resp.data);
    //   });
    // },
    getDataTotal() {
      return this.gerarRelatorioTotal().then(resp => {
        return this.montarDownloadTotal(resp.data);
      });
    },
    downloadExcelAll() {
      GetExcelAdminById().then(
        (res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data],{
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "RelatorioCuponsModerados.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$store.commit("interacoes/SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: error.response.data,
          });
        }
      );
    },
    moderarItems(id) {
      console.log(id)
      this.$store.commit("cupom/SET_TIPO_FORMULARIO", "Moderar");
      this.$store.commit("cupom/SET_CUPOM_TELA", "Moderar");
      this.$store.commit("cupom/SET_ID_FORMULARIO_EDITAR", id);
      // cupomListarProdutos(id);
    },
  },
  filters: {
    filterData,
    formataCPF
  },
  mounted() {
    this.pageNumber = 1;
    // this.gerarRelatorio();
    this.buscaStatus();
    this.verificaComponente();
    this.montarDownload();
    cupomListarTodos();
  },
  watch: {
    camposInput() {
      this.verificaComponente();
    },
    dadosTabela() {
      this.semDados = this.dadosTabela.length <= 0;
    },
    statusCupom() {
      this.semStatus = this.statusCupom.length <= 0;
    },
    pageNumber() {
      this.verificaComponente();
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.cupom {
  .tabela-subtitulo {
    justify-content: normal;
  }
  // .tabela-subtitulo > div:not(.tabela-paginacao) {
  //   width: 100%;
  //   max-width: 305px;
  // }
  .cupom-checkbox {
    flex-direction: row;
    align-self: center;
    margin-bottom: 0;
    height: 38px;
    align-items: center !important;

    .formulario-item-label {
      margin-bottom: 5px;
      padding-left: 10px;
    }
  }
  .btns-formulario {
    margin-bottom: 12px;
    button {
      min-width: auto;
    }
  }
}
.tabela-linha-cupom-meio {
  min-width: 115px !important;
  max-width: 115px !important;
}
.tabela-linha-cupom-numero,
.tabela-linha-cupom-email{
  overflow: hidden;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
